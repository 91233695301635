// @ts-ignore
import React, { Component, createContext } from "react";
import { MenuFadeIn } from "../shared/transitions";
import { LogoText } from "../../components/shared/logo";
import instagramIcon from "../../images/instagram-icon.svg";
import facebookIcon from "../../images/facebook-icon.svg";
import linkedinIcon from "../../images/linkedin-icon.svg";
import { Link, navigate } from "gatsby";

const MenuItems: IMenuItem[] = [
  {
    link: "/",
    text: "Home",
  },
  {
    link: "/our-work",
    text: "Our Work",
  },
  {
    link: "/services",
    text: "Services",
  },
  {
    link: "/user-experience",
    text: "User Experience",
  },
  {
    link: "/about-us",
    text: "About us",
  },
  {
    link: "/blog",
    text: "Blog",
  },
  {
    link: "/contactus",
    text: "Get in touch",
  },
  // {
  //   link: '/blog',
  //   text: 'Blog',
  // },
];

interface IMenuProps {
  iconColor: string;
  toggled: boolean;
  toggleMenu: (val: boolean) => {};
}

interface IMenuState {
  modalOpen: boolean;
}

export default class Menu extends Component<IMenuProps, IMenuState> {
  location: Location;
  isRoot: boolean;

  static defaultProps = {
    iconColor: "#fff",
    toggled: false,
    toggleMenu: () => {},
  };

  state: IMenuState = {
    modalOpen: false,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    this.location = window.location;
    this.isRoot = this.location.pathname === "/";
  }

  render(): React.ReactElement {
    return (
      <MenuContext.Consumer>
        {({ menuToggled, toggleMenu }) => (
          <MenuFadeIn pose={menuToggled ? "enter" : "exit"} className="menu">
            <div className="menu__container ">
              <ul className="menu__list">
                {MenuItems.map(item => (
                  <li key={item.text} className="menu__item">
                    <Link
                      to={item.link}
                      activeClassName="active"
                      onClick={toggleMenu}
                    >
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>

              <div className="menu__contact__container">
                <LogoText />

                <p>
                  <a
                    href="https://goo.gl/maps/3AwjAKDbhKfAzEw19"
                    target="_blank"
                    rel="noreferrer"
                  >
                    165 / 15 Aberdeen St, Perth WA 6000
                  </a>
                </p>

                <div className="menu__social__media">
                  <a
                    href="https://www.instagram.com/divivgroup/"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon__link"
                  >
                    <img
                      src={instagramIcon}
                      style={{ width: "15px", height: "15px" }}
                      alt="Follow Diviv on Instagram"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/divivgroup/"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon__link"
                  >
                    <img
                      src={facebookIcon}
                      style={{ height: "15px" }}
                      alt="Follow Diviv on Facebook"
                    />{" "}
                  </a>

                  <a
                    href="https://www.linkedin.com/company/17890171"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon__link"
                  >
                    <img
                      src={linkedinIcon}
                      style={{ width: "15px" }}
                      alt="Follow Diviv on Linkedin"
                    />{" "}
                  </a>
                </div>

                <p>
                  <span>T</span> <a href="tel:1300875978">1300 875 978</a>
                </p>
                <p>
                  <span>E</span>{" "}
                  <a href="mailto:hello@diviv.com.au">hello@diviv.com.au</a>
                </p>
              </div>
            </div>
          </MenuFadeIn>
        )}
      </MenuContext.Consumer>
    );
  }
}

export const MenuContext = createContext({
  menuToggled: false,
  toggleMenu: () => null,
});

interface IMenuItem {
  link: string;
  text: string;
}
