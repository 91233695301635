import posed from "react-pose";
// tslint:disable-next-line: quotemark
import React, { PureComponent, Component } from "react";
export const fadeUpCharacters = {
  visible: {
    y: 0,
    delay: ({ charIndex }) => charIndex * 100,
    transition: {
      duration: 500,
    },
  },
  hidden: {
    y: "100%",
  },
};
// simple Fade Up Div animation
export const FadeUpBox: any = posed.div({
  enter: {
    y: 0,
    staggerChildren: 500,
    transition: {
      duration: 500,
    },
    delayChildren: ({ delay }) => (delay ? delay : 0),
  },
  exit: {
    y: ({ offset }) => (offset ? offset : 50),
  },
});

export const FadeUp = posed.div({
  visible: {
    y: 0,
    delay: ({ delay }) => (delay ? delay : 1000),
    transition: {
      duration: 500,
    },
  },
  hidden: {
    y: "130%",
  },
});

export const StaggerContainer: any = posed.div({
  enter: { staggerChildren: 1500 },
  exit: {},
});

export const FadeUpList: any = posed.div({
  enter: { y: 0, staggerChildren: 500 },
  exit: {
    y: 50,
    transition: { duration: 200 },
  },
});

// simple Fade In Div animation
export const FadeInItem: any = posed.li({
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: ({ duration }) => (duration ? duration : 300),
  },
});

// simple Fade In Div animation
export const FadeIn: any = posed.div({
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: ({ duration }) => duration,
  },
});

export const InAndUp: any = posed.div({
  enter: { y: 0 },
  exit: {
    y: "100%",
  },
});

// simple Fade In Div animation
export const MenuFadeIn: any = posed.nav({
  enter: { opacity: 1, zIndex: 20 },
  exit: {
    opacity: 0,
    zIndex: -1,
    transition: ({ duration }) => (duration ? duration : 300),
  },
});

export const ExpandRightFeature: any = posed.div({
  enter: { width: "150px" },
  exit: { width: 0 },
});

// simple Fade In Right Div animation
export const FadeInRight: any = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: {
    opacity: 0,
    x: 20,
    transition: ({ duration }) => (duration ? duration : 300),
  },
});
export const FadeInLeft: any = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: {
    opacity: 0,
    x: -20,
    transition: ({ duration }) => (duration ? duration : 300),
  },
});
// simple Fade In Right Div animation
export const ImageInRight: any = posed.img({
  enter: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: ({ duration }) => duration,
  },
});

// simple Fade up Bottom Div animation
export const FadeUpBottom: any = posed.div({
  enter: { opacity: 1, y: 0, height: "100%" },
  exit: {
    opacity: 0,
    y: 50,
    height: "100%",
    transition: ({ duration }) => duration,
  },
});

// menu List Parent Animation, stagger all children animations
// delay the x position before all child animations complete
export const MenuList: any = posed.ul({
  closed: {
    x: "100%",
    staggerChildren: 150,
    delay: 900,
    transition: {
      open: { type: "tween", duration: 300 },
    },
  },
  open: {
    x: 0,
    staggerChildren: 150,
    transition: {
      open: { type: "tween", duration: 300 },
    },
  },
});

// menu list item animation from right to left and opacity
export const ListItem: any = posed.li({
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      open: { type: "tween" },
    },
  },
  open: {
    opacity: 1,
    x: 0,
    transition: {
      open: { type: "tween" },
    },
  },
});

export const Expand = posed.div({
  exit: {
    scale: 0.2,
    opacity: 0,
  },
  enter: {
    scale: 1,
    opacity: 1,
    delay: ({ delay }) => (delay ? delay : 0),
    transition: ({ duration }) => (duration ? duration : 300),
  },
});

export const ZoomText = posed.span({
  enter: {
    scale: 2.5,
    marginTop: "15px",
    marginBottom: "15px",
    transition: {
      ease: "easeInOut",
    },
  },
  exit: {
    marginTop: "0px",
    marginBottom: "0px",
    scale: 1,
    transition: {
      ease: "easeInOut",
    },
  },
});

export const DropDownRight = posed.span({
  enter: {
    y: 0,
    transition: {
      ease: "easeIn",
    },
  },
  exit: {
    y: "-100%",
    transition: {
      ease: "easeIn",
    },
  },
});
