/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import Header from "../components/global/header";
// import { Footer } from "../components/global/footer";
// import HeroTransition from "../components/shared/transitions";

const GlobalLayout = props => {
  const { children, hideBanner, location } = props;
  const { state = {} } = location;
  const { modal } = state || false;
  return (
    <div className="layout-container">
      <Header />
      {children}
    </div>
  );
};

export default GlobalLayout;
