import React, { useState, useEffect, useRef } from "react";
import Logo from "../shared/logo";
import { Link } from "gatsby";
import Menu, { MenuContext } from "./menu";
import instagramIcon from "../../images/instagram-icon.svg";
import facebookIcon from "../../images/facebook-icon.svg";
import linkedinIcon from "../../images/linkedin-icon.svg";
import { Cross, Cross as Hamburger } from "hamburger-react";

const Header = ({
  siteTitle,
  fillColor,
  buttonColor,
}: {
  siteTitle: string;
  fillColor?: string;
  buttonColor?: string;
}) => {
  const [menuToggled, setMenuToggled] = useState(false);
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const doc = document.documentElement;

    window.addEventListener("scroll", function() {
      const header = headerRef.current;
      const isScrolled =
        (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0) >= 50;

      if (header && isScrolled) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });
  }, [menuToggled, headerRef]);

  function toggleMenu() {
    if (menuToggled) {
      document.body.classList.remove("menu-active");
      setMenuToggled(false);
      return false;
    } else {
      document.body.classList.add("menu-active");
      setMenuToggled(true);
      return true;
    }
  }

  function closeMenu() {
    document.body.classList.remove("menu-active");
    setMenuToggled(false);
  }

  return (
    <MenuContext.Provider value={{ menuToggled, toggleMenu }}>
      <MenuContext.Consumer>
        {({ menuToggled, toggleMenu }) => (
          <>
            <div
              id="header__container"
              className="header__container"
              ref={headerRef}
            >
              <Link to="/" className="header__logo" onClick={() => closeMenu()}>
                <Logo fill={fillColor ? fillColor : "#fff"} />
              </Link>

              <ul className="nav-contact-details">
                <li>
                  Call: <a href="tel:1300875978">1300 875 978</a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://www.instagram.com/divivgroup/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={instagramIcon}
                      style={{ width: "15px", height: "15px" }}
                      alt="Follow Diviv on Instagram"
                    />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/divivgroup/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={facebookIcon}
                      style={{ height: "15px" }}
                      alt="Follow Diviv on Facebook"
                    />{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/17890171"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedinIcon}
                      style={{ width: "15px" }}
                      alt="Follow Diviv on Linkedin"
                    />{" "}
                  </a>
                </li>
              </ul>

              <Cross
                toggle={() => toggleMenu()}
                toggled={menuToggled}
                rounded
                color="#fff"
              />
            </div>
            <Menu
              toggleMenu={() => toggleMenu()}
              iconColor={buttonColor ? buttonColor : "#fff"}
              toggled={menuToggled}
            />
          </>
        )}
      </MenuContext.Consumer>
    </MenuContext.Provider>
  );
};

Header.defaultProps = {
  siteTitle: ``,
};

interface ISectionParams {
  startY: number;
  endY: number;
  className: string;
}

export default Header;
