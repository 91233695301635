/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// const { linkResolver } = require('./src/utils/linkResolver');

// registerLinkResolver(linkResolver);

// import LogRocket from 'logrocket';
// import './src/static/polyfills/picturefill.min.js';

// LogRocket.init('4jmdy7/diviv-website');
import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import "./src/styles/index.scss";
// import { setConfig } from "react-hot-loader";

// setConfig({
//   pureSFC: true,
//   pureRender: true,
// });

// import "@babel/polyfill";
// gatsby-browser.js

// DO NOT USE THIS FOR PROD BUILD
// exports.wrapPageElement = ({ element, props }) => {
//   return <GlobalLayout {...props}>{element}</GlobalLayout>;
// };

export const wrapRootElement = ({ element }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>;
};
