import React, { Component } from "react";
import { useStaticQuery, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

class Logo extends Component<ILogoProps> {
  static defaultProps = {
    fill: "#000",
    fullHeight: false,
    imageUrl: "",
    showBackground: false,
  };
  renderImageBackground(): React.ReactElement {
    return this.props.showBackground ? (
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "culture_bg.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink=""
            viewBox="0 0 145.7 98.7"
            xmlSpace="preserve"
            style={{ position: "absolute", transform: "scale(1)" }}
            preserveAspectRatio="xMidYMid slice"
            className={this.props.fullHeight ? "fullHeight" : ""}
          >
            <clipPath id="clip_path_logo">
              <path
                d="M90.4,0.7c-4.6,4.6-9.2,9.2-13.8,13.7c-1.3,1.3-2.6,2.6-3.9,3.9C68.2,13.7,63.6,9.1,59,4.5c-1.5-1.5-3-3-4.5-4.5
	c-1.8,1.8-3.7,3.7-5.5,5.5c-4.3,4.3-8.6,8.6-12.9,12.9c-5.2,5.2-10.5,10.5-15.7,15.7c-4.5,4.5-9,9-13.5,13.5c-2,2-4.1,4.1-6.1,6.1
	C0.5,54,0.2,54.3,0,54.5c5.8,5.8,11.6,11.6,17.4,17.4c6.3,6.3,12.6,12.6,18.9,18.9c3.7,3.7,8.1,6.8,13.4,7.6c1,0.2,2,0.2,3.1,0.2
	c5.2,0,36.9,0.2,40.9,0.1c1,0,2-0.1,2.9-0.3c4.8-0.9,9-3.6,12.3-7.1c1.2-1.2,2.4-2.4,3.6-3.6c4.8-4.8,9.6-9.6,14.4-14.4
	c6-6,12-12,18-18c0.3-0.3,0.6-0.6,0.8-0.8c-1.8-1.8-3.7-3.7-5.6-5.5c-4.3-4.3-8.6-8.6-12.9-12.8c-5.3-5.2-10.5-10.5-15.8-15.7
	c-4.5-4.5-9-9-13.5-13.5c-2-2-4.1-4-6.1-6.1c-0.2-0.2-0.5-0.5-0.7-0.7L90.4,0.7z"
              />
            </clipPath>

            <image
              style={{ height: "100%" }}
              clipPath="url(#clip_path_logo)"
              xlinkHref={data.placeholderImage.childImageSharp.fluid.src}
            />
            <rect
              clipPath="url(#clip_path_logo)"
              style={{
                fill: `${this.props.fill}`,
                opacity: 0.5,
                height: "100%",
                width: "100%",
              }}
            />
          </svg>
        )}
      />
    ) : null;
  }

  render() {
    return (
      //xlink:href
      <>
        <div className="svg-container logo">
          {this.renderImageBackground()}
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 145.7 98.7"
            preserveAspectRatio="xMidYMid slice"
            xmlSpace="preserve"
            fill={this.props.fill}
            className={this.props.fullHeight ? "fullHeight" : ""}
          >
            <path
              d="M91.2,84.5c-0.8-0.2-1.5-0.5-2.2-0.8c-0.8-0.4-1.6-0.9-2.4-1.5c-0.9-0.7-1.7-1.4-2.5-2.2l-1.1-1.1l-0.7-0.7l0.7-0.7l0.6-0.6
      l2-2l2-2l2-2c1.3-1.3,2.6-2.7,3.7-4.2c1.1-1.4,2-2.9,2.8-4.5c0.8-1.5,1.4-3.1,1.8-4.8c0.4-1.6,0.7-3.3,0.7-5c0-1.4-0.2-2.7-0.4-4.1
      c-0.3-1.4-0.8-2.8-1.4-4.1c-0.6-1.4-1.4-2.7-2.3-4c-1.1-1.4-2.2-2.7-3.5-3.8l-2.4-2.4l-5.8-5.8l-0.7-0.7l0.7-0.7l1.2-1.2l6.2-6.2
      l0.7-0.7l0.7,0.7l7.9,7.9l9.3,9.3l9.3,9.3L126,54l0.7,0.7l-0.7,0.7l-5.5,5.5l-6.9,6.9l-6.9,6.9l-6.9,6.9c-0.5,0.5-1,1-1.6,1.4
      c-0.5,0.4-1.1,0.7-1.6,1c-0.5,0.3-1.1,0.4-1.6,0.6c-0.6,0.1-1.1,0.2-1.7,0.2C92.5,84.8,91.8,84.7,91.2,84.5 M90.4,0.7l-4.6,4.6
      l-9.2,9.2l-3.2,3.2l-0.7,0.7l-0.7-0.7l-3.2-3.2l-9.2-9.2l-4.6-4.6L54.5,0l-0.7,0.7L0.7,53.8L0,54.5l0.7,0.7l9,9l17.9,17.9l9,9
      c1.2,1.2,2.5,2.3,3.9,3.3c1.3,0.9,2.6,1.7,4,2.3c1.3,0.6,2.7,1.1,4.1,1.4c1.3,0.3,2.7,0.5,4.1,0.4c1.7,0,3.4-0.2,5-0.7
      c1.6-0.4,3.2-1.1,4.8-1.8c1.6-0.8,3.1-1.8,4.5-2.8l0.1-0.1l-9.8-9.8l-0.6,0.3c-0.7,0.4-1.4,0.6-2.2,0.8c-0.7,0.2-1.3,0.3-2,0.3
      c-0.6,0-1.1-0.1-1.7-0.2c-0.6-0.2-1.2-0.4-1.7-0.7c-0.6-0.3-1.1-0.6-1.6-1c-0.6-0.4-1.1-0.9-1.6-1.4L39,74.8l-6.9-6.9l-6.9-6.9
      l-5.6-5.5l-0.7-0.7l0.7-0.7l7.9-7.9l9.3-9.3l9.3-9.3l7.9-7.9l0.7-0.7l0.7,0.7l1.2,1.2l5.1,5.1l2.5,2.5l8,8l2.4,2.4l4.8,4.8l2.4,2.4
      c0.5,0.5,1,1,1.4,1.6c0.4,0.5,0.7,1,1,1.6c0.2,0.5,0.4,1.1,0.6,1.6c0.1,0.5,0.2,1.1,0.2,1.7c0,0.7-0.1,1.3-0.3,2
      c-0.2,0.8-0.5,1.5-0.8,2.2c-0.4,0.8-1,1.6-1.6,2.4c-0.7,0.9-1.4,1.7-2.2,2.5l-3.9,3.9L73.5,68l-0.7,0.7L72.1,68l-2.6-2.6l-2-2l-2-2
      c-0.8-0.8-1.5-1.6-2.1-2.5c-0.6-0.8-1-1.6-1.5-2.4c-0.4-0.7-0.6-1.4-0.8-2.2c-0.2-0.7-0.3-1.3-0.3-2c0-1.1,0.3-2.3,0.8-3.3
      c0.3-0.6,0.6-1.1,1-1.6c0.4-0.6,0.9-1.1,1.3-1.6l2.4-2.4l0.7-0.7l-0.7-0.7l-8-8l-0.7-0.7L57,34.1l-2.4,2.4c-1.2,1.2-2.3,2.5-3.3,3.9
      c-0.9,1.3-1.7,2.6-2.3,4c-0.6,1.3-1.1,2.7-1.4,4.1c-0.3,1.3-0.4,2.7-0.5,4c0,1.7,0.2,3.4,0.7,5c0.4,1.7,1,3.3,1.8,4.8
      c0.8,1.6,1.8,3.1,2.8,4.5c1.1,1.5,2.4,2.9,3.7,4.2l2,2l2,2l2,2l2,2l8.8,8.8l0.6,0.6l0.6,0.6l0.6,0.6c1.3,1.3,2.7,2.6,4.2,3.7
      c1.4,1.1,2.9,2,4.5,2.8c1.5,0.8,3.1,1.4,4.8,1.8c1.6,0.4,3.3,0.6,5,0.6c1.4,0,2.7-0.2,4.1-0.4c1.4-0.3,2.7-0.8,4-1.4
      c1.4-0.7,2.7-1.4,4-2.3c1.4-1.1,2.7-2.2,3.8-3.5l9-9L145,55.1l0.7-0.7l-0.7-0.7l-53.1-53L91.2,0L90.4,0.7z"
            />
          </svg>
        </div>
      </>
    );
  }
}

export default Logo;

export const LogoText: React.FC = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 277.99 98.35"
      className="logo-text"
    >
      <path
        d="M72.85,10.84V.26H69.34c-3.62,0-9.62,4.34-9.62,11.88V32.55h0v3.27l-3.07-2.51a33,33,0,0,0-21.42-7.46,33.46,33.46,0,0,0-25,10.82A36.52,36.52,0,0,0,62.19,88,35.22,35.22,0,0,0,72.85,62.31Zm-20,67.89A22.53,22.53,0,0,1,36.4,85.57a22.28,22.28,0,0,1-16.33-6.84,22.26,22.26,0,0,1-6.89-16.46,22.12,22.12,0,0,1,6.89-16.4,23,23,0,0,1,32.53-.29l.29.29a22.12,22.12,0,0,1,6.89,16.4,22.26,22.26,0,0,1-6.89,16.46"
        transform="translate(0 -0.26)"
        fill={props.fill}
      />
      <path
        d="M191.16,97.55c3.61,0,9.62-4.34,9.62-11.88V27h-3.52c-3.61,0-9.62,4.34-9.62,11.88V97.55Z"
        transform="translate(0 -0.26)"
        fill={props.fill}
      />
      <path
        d="M92.52,97.55c3.61,0,9.62-4.34,9.62-11.88V27H98.62C95,27,89,31.34,89,38.88V97.55Z"
        transform="translate(0 -0.26)"
        fill={props.fill}
      />
      <path
        d="M268.15,27a4.91,4.91,0,0,0-1.66.29,7,7,0,0,0-1.6.79,6.42,6.42,0,0,0-1.34,1.15,8.83,8.83,0,0,0-1,1.44l-2.06,5.93-1.65,4.72-1.22,3.41-.06.15-.12.31-.6,1.56-1.95,5-1,2.55-1,2.54-1,2.54-1,2.55L248,69.29,246.7,73l-1.43,3.68-.44,1.15L244,80.11l-.45,1.15-.43-1.16-1.33-3.43-2.4-6.22L234.52,58l-2.45-6.23-2-5-.62-1.61-.09-.26-.06-.12-1.17-3.31-1.74-4.91-2.15-5.94a4.69,4.69,0,0,0-.87-1.37A6.63,6.63,0,0,0,222,28.1a7.44,7.44,0,0,0-1.6-.79,5.21,5.21,0,0,0-1.57-.31h-9.8l.23.6.23.59.23.6.23.6.23.61.24.6.23.61.23.61,2.68,7,2.67,6.93,16.15,43.92a11.79,11.79,0,0,0,22.26,0l16.15-44,1.06-2.74,1.74-4.52,1.74-4.52,1.7-4.52.24-.59.23-.6.22-.6L278,27Z"
        transform="translate(0 -0.26)"
        fill={props.fill}
      />
      <path
        d="M169.5,27a5.28,5.28,0,0,0-1.67.29,7.37,7.37,0,0,0-1.59.79,6.42,6.42,0,0,0-1.34,1.15A5,5,0,0,0,164,30.6l-2,5-1.94,5-1.17,3a.23.23,0,0,1-.06.15l-.12.31-.6,1.55-1.94,5-1,2.54-1,2.55-1,2.54-1,2.54-2.85,7.37L148.07,72l-1.43,3.68-.44,1.14-.88,2.29-.44,1.14-.45-1.14-1.36-3.48-2.4-6.22L135.87,57l-2.41-6.22-2-5-.62-1.61-.09-.25-.06-.13-1.17-3-1.95-5-1.94-5a4.87,4.87,0,0,0-.86-1.37,6.68,6.68,0,0,0-1.34-1.15,7,7,0,0,0-1.6-.79,4.91,4.91,0,0,0-1.66-.29h-9.84l.23.6.23.59.23.6.23.6.24.61.23.6.23.61.23.61,2.69,7,2.67,6.93,16.14,43.92a11.79,11.79,0,0,0,22.26,0l16.15-44,1.06-2.74,1.74-4.52,1.74-4.52,1.74-4.52.22-.59.24-.6.22-.6.23-.59Z"
        transform="translate(0 -0.26)"
        fill={props.fill}
      />
    </svg>
  );
};

LogoText.defaultProps = {
  fill: "#29e9fe",
};

interface ILogoProps {
  fill: string;
  fullHeight: boolean;
  showBackground: boolean;
}
